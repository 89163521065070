
  import { defineComponent, onMounted, ref } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';

  export default defineComponent({
    name: 'banks-creating',
    components: {
      ErrorMessage,
      Field,
      Form,
    },
    setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      const bankSchema = Yup.object().shape({
        titleEn: Yup.string()
          .required(() => translate('EN_NAME_IS_REQUIRED_FIELD'))
          .label('English Name'),
        titleAr: Yup.string()
          .required(() => translate('AR_NAME_IS_REQUIRED_FIELD'))
          .label('Arabic Name'),
        name: Yup.string().label('English Name'),
        password: Yup.string()
          .min(4, () => translate('PASSWORD_MIN_4'))
          .max(20, () => translate('PASSWORD_MAX_20'))
          .matches(/^(?=.*[a-z])/, translate('PASSWORD_AT_LEAST_ONE_LOWER'))
          .matches(/^(?=.*[A-Z])/, translate('PASSWORD_AT_LEAST_ONE_UPPER'))
          .matches(/^(?=.*\d)/, translate('PASSWORD_AT_LEAST_ONE_NUMBER'))
          .matches(
            /^.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?].*$/,
            translate('PASSWORD_AT_LEAST_ONE_SPECIAL')
          )
          .label('Password'),
        verifyPassword: Yup.string()
          .oneOf([Yup.ref('password'), null], () =>
            translate('PASSWORDS_MUST_MATCH')
          )
          .label('Verify Password'),
        email: Yup.string()
          .email(() => translate('EMAIL_NOT_VALID'))
          .label('Email'),
      });

      const onSubmitCreate = async (values) => {
        delete values.verifyPassword;
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        await store.dispatch(Actions.CREATE_BANK, values);
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_CREATED_BANK'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            router.push({ name: 'banks-listing' });
          });
        }
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('banksCreating'), [
          translate('appraisals'),
        ]);
      });

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        onSubmitCreate,
        submitButton,
        translate,
        bankSchema,
        goBack,
        can,
      };
    },
  });
